




import DocumentUploadSection from "@/components/mobile/DocumentUploadSection.vue";
import Vue from "vue";

export default Vue.extend({
  components: {
    DocumentUploadSection
  }
});
