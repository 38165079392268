








































import Vue, { PropType } from 'vue'
import { DataTableHeader } from 'vuetify';
import nameof from '@/utility/nameof';
import GeneralDocumentTransferObject from '../../types/GeneralFileTransferObject';
import GeneralDocumentUploadDialog from "../dialogs/GeneralDocumentUploadDialog.vue";
import { appApiClient } from '@/services/apiService';
import DocumentTypes from "@/types/DocumentTypes";
import momentUtility from "@/utility/momentUtility";
import SnackbarModule from '../../store/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import FileDownloadUtility from '@/utility/fileDownloadUtility';
const snackbarModule = getModule(SnackbarModule);

const headers: Array<DataTableHeader<GeneralDocumentTransferObject>> = [
    { text: "Post Title", value: nameof<GeneralDocumentTransferObject>("documentTitle") },
    { text: "Post Desc", value: nameof<GeneralDocumentTransferObject>("desc") },
    { text: "Created At", value: nameof<GeneralDocumentTransferObject>("createdAt") },
    { text: "", value: nameof<GeneralDocumentTransferObject>("id") },
    { text: "", value: nameof<GeneralDocumentTransferObject>("isActive") },
];

interface Data {
  headers: Array<DataTableHeader<GeneralDocumentTransferObject>>,
  items: GeneralDocumentTransferObject[],
  newPostDialog: boolean,
  loading: boolean
}

export default Vue.extend({
  components: { 
    GeneralDocumentUploadDialog 
  },
  props: ["title", "path"],
  data() : Data {
    return {
      headers,
      items: [],
      newPostDialog: false,
      loading: false
    }
  },
  async mounted() {
    try {
      this.loading = true;
      const response = await appApiClient.get(`api/${this.path}/GetDocuments/`);
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load documents.");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    formatDate: momentUtility.formatDate,

    onNewPost(item: GeneralDocumentTransferObject) {
      this.items.push(item);
    },

    async onDelete(documentId: number) {
      try {
        this.loading = true;
        await appApiClient.delete(`api/${this.path}/Delete/${documentId}`);

        this.items = this.items.filter(document => document.id !== documentId);
      } catch {
        snackbarModule.setSnackbarMessage("Failed to delete document.");
      } finally {
        this.loading = false;
      }
    },

    async onDownload(item: GeneralDocumentTransferObject) {
      try {
        if (item.externalURL) {
          window.open(item.externalURL, '_blank')
        }

        this.loading = true;
        const response = await appApiClient.get(`api/${this.path}/DownloadDocument/${item.fileKey}`, {
          responseType: 'arraybuffer'
        });

        FileDownloadUtility.DownloadFile(response.data, item.fileName);
      } catch(error) {
        snackbarModule.setSnackbarMessage("Failed to download document.");
      } finally {
        this.loading = false;
      }
    }
  }
});
