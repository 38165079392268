























































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import { appApiClient } from '../../services/apiService';
import SnackbarModule from '../../store/snackbarModule';

const snackbarModule = getModule(SnackbarModule);

interface DocumentUploadModel {
  file?: File,
  documentTitle: string,
  documentDesc: string,
  externalURL?: string 
}

@Component({})
export default class MessageGroupCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop() path!: string;
  @Ref() private readonly form!: VForm;

  public model: DocumentUploadModel = {
    file: undefined,
    documentTitle: "",
    documentDesc: "",
    externalURL: undefined
  }

  public loading = false;
  public externalLink = false;

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  @Watch("externalLink")
  private onLinkToggleChange(value: boolean) {
    if (value) return this.model.file = undefined;

    this.model.externalURL = undefined;
  }

  public async onDocumentUpload() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;

      const formData = new FormData();

      if(this.model.file) {
        formData.append("file", this.model.file, this.model.file.name);
      }

      if(this.model.externalURL) {
        formData.append("externalURL", this.model.externalURL);
      }

      formData.append("documentTitle", this.model.documentTitle);
      formData.append("documentDesc", this.model.documentDesc);

      const response = await appApiClient.post(`api/${this.path}/UploadDocument`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });

      this.$emit("newPost", response.data);

      snackbarModule.setSnackbarMessage("Document uploaded.");
      this.syncedDialog = false;
    } catch {
      snackbarModule.setSnackbarMessage("Could not upload document.");
    } finally {
      this.loading = false;
    }
  }
}
